.main-box {
  position: relative;
  padding-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;

  user-select: none;
}

.main-box h1,
p {
  margin: 0;
}

.main-title {
  font-size: 4rem;
}

.main-number {
  font-size: 12rem;
}

.main-info {
  background-color: white;
  /* box-shadow: #1e1e1ea1 0.1rem 0.1rem 0.2rem; */
  row-gap: 0rem;
  width: 7.5rem;
  height: 2.5rem;
  border-radius: 1rem;
}

.material-symbols-outlined {
  color: black;
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

@keyframes onLeave {
  
    from {
      width: 90rem;
      height: 50rem;
      margin-top: 5rem;
    }
    to {
      width: 7.5rem;
      height: 2.5rem;
      margin-top: 5rem;
    }
  }
