.minister-container {
  background-color: white;
  color: var(--dark-text-color);
  box-shadow: #1e1e1ea1 0.1rem 0.3rem 0.5rem;
  border-radius: 1rem;
  overflow: hidden;
  padding: 2rem;
  animation: onCreate 0.75s forwards;

  margin-bottom: 3rem;

  user-select: text;
}

.minister-container ul {
  padding: 0;
  margin: 0;
}

.container-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;

  margin-bottom: 3rem;
}

@keyframes onCreate {
  from {
    width: 7.5rem;
    height: 2.5rem;
    margin-top: 5rem;
  }

  to {
    width: 90rem;
    height: auto;
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 76.8rem) {
  @keyframes onCreate {
    to {
      max-width: fit-content;
    }
  }
}