.custom-btn{
    border-radius: 1rem;
    width: 7rem;
    height: 3rem;
    border: none;

    color: white;

    transition: background-color .5s;
}

.custom-btn:hover{
    cursor: pointer;
}