:root{
  --left-side-color: #FF4545;
  --right-side-color: #45A6FF;
  --dark-text-color: #464646 ;

  font-size: 62.5%;
  scrollbar-width: none;
}

:root::-webkit-scrollbar{
  display: none;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark-text-color);

  transition: background-color 1s;

  display: flex;
  justify-content: center;
  height: auto;
  font-size: 1.6rem;
}

h1, h2 {
  font-family: 'Anton', sans-serif;
}

p{
  font-family: 'PT Serif', serif;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}