.item {
    max-width: 100%;
    height: auto;
    padding: 0 2rem;

    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    column-gap: 10rem;
    justify-content: space-between;
    padding: 0;
}

li + li {
    margin-top: 5rem;
}

.minister-role {
    margin-top: -0.8rem;
    margin-bottom: 1rem;
    font-style: italic;
}

.minister-role + .minister-role {
    margin-top: -1.2rem;
}

.minister-source{
    font-size: small;
    font-style: italic;
}

.minister-source:hover{
    cursor: pointer;
}

.column-left {
    grid-column-start: 1;
    max-height: 30rem;
}

.column-left img {
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: 1rem;
}

.column-right{
    grid-column-start: 2;
    text-align: right;
}

@media only screen and (max-width: 670px) {
    .item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        height: auto;
    }

    .column-right{
        margin-top: 1rem;
        text-align: center;
    }
  }