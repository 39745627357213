.disclaimer-box {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    max-width: 50rem;
    z-index: 100;

    background-color: rgba(255, 255, 255, 0.85);
    color: var(--dark-text-color);

    padding: 2rem;
    border-radius: 1rem;

    backdrop-filter: blur(3px);
}

.contact a{
    color: var(--dark-text-color);
    font-weight: bold;
}

.disclaimer-box p + p {
    margin: 2rem 0;
}