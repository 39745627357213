.footer {
    margin-top: auto;
    position: relative;
    text-align: center;
    color: white;
}

.footer a {
    text-decoration: none;
    font-weight: bold;
    color: white;
}

.footer-disclaimer:hover {
    cursor: pointer;
}